<template>
  <el-dialog
    :title="id ? '编辑' : '新增'"
    :visible.sync="show"
    center
    :fullscreen="true"
    :before-close="handleClose"
  >
    <el-row :gutter="20">
      <el-form label-position="left" label-width="120px" class="form-container">
        <!--表单顶部 -->
        <div class="form-top">
          <el-form-item label="备注：" class="necessary">
            <el-input
              v-model="form.remark"
              placeholder="给这条消息起个名字便于查询"
            ></el-input>
          </el-form-item>
        </div>
        <!-- 表单中部 -->
        <div class="form-body">
          <div class="form-body-left">
            <el-form-item label="消息类型：">
              <el-radio-group v-model="form.msgtype" size="medium">
                <el-radio-button label="text">文本</el-radio-button>
                <el-radio-button label="news">图文</el-radio-button>
                <el-radio-button label="voice">音频</el-radio-button>
                <el-radio-button label="image">图片</el-radio-button>
                <el-radio-button label="miniprogrampage">小程序卡片</el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="平台：">
              <el-select
                class="margin-right-twentyFour"
                v-model="platform"
                style="width: 300px"
                placeholder="平台"
                multiple
                clearable
                @change="selectAccountChannel()"
              >
                <el-option
                  v-for="item in messagePlatformListDetail"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                />
              </el-select>
              <span>公众号：{{ selectChannel ? selectChannel.channelName : '' }}</span>
            </el-form-item>
            <el-form-item label="标签：">
              <el-select
                class="margin-right-twentyFour"
                style="width: 300px"
                v-model="categoryId"
                multiple
                filterable
                clearable
                placeholder="选择标签"
                @change="selectAccountChannel()"
              >
                <el-option
                  v-for="item in classifyList"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="消息内容："
              class="necessary"
              style="margin-bottom: 14px"
            >
              <template v-if="form.msgtype === 'text'">
                <div class="message-box">
                  <el-input
                    type="textarea"
                    @blur="handleBlur"
                    style="width: 450px; height: 400px"
                    :autosize="{ minRows: 18 }"
                    placeholder="请输入内容"
                    v-model="textStr"
                  >
                  </el-input>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                  "
                >
                  <el-button
                    class="button-mini"
                    type="text"
                    style="margin-right: 10px"
                    @click="(showTitleDialog = true), (insertInfoType = 1)"
                  >插入标题
                  </el-button
                  >
                  <insert-book
                    :channel="selectChannel"
                    @success="handleSelectSuccess"
                  />
                  <insert-page
                    :channel="selectChannel"
                    @success="handleSelectSuccess"
                  />

                  <el-button
                    class="button-mini"
                    type="text"
                    @click="handleInsert('{$nickname}')"
                    style="margin-right: 10px"
                  >插入用户昵称
                  </el-button
                  >
                  <insert-activity
                    :channel="selectChannel"
                    :type="form.msgtype"
                    @success="handleActivityInsert"
                  />
                  <el-button
                    class="button-mini"
                    type="text"
                    @click="showMiniDialog = true"
                  >插入小程序
                  </el-button
                  >
                  <el-button
                    class="button-mini"
                    type="text"
                    @click="showTemplate = true"
                  >插入模板
                  </el-button
                  >
                  <el-button
                    class="button-mini"
                    type="text"
                    @click="checkCoinStatus"
                    v-if="[0, 1, 3, 6, 7].includes(selectChannel.platform)"
                  >插入书币
                  </el-button
                  >
                </div>
              </template>
              <el-form v-if="form.msgtype === 'news'" label-width="50px">
                <el-form-item label="链接">
                  <span
                    style="margin-right: 10px; display: block"
                    v-if="form.novelName"
                  >{{ form.novelName }}</span
                  >
                  <insert-book
                    :channel="selectChannel"
                    @success="handleSelectSuccess"
                  />
                  <insert-page
                    :channel="selectChannel"
                    @success="handleSelectSuccess"
                  />
                  <insert-activity
                    :channel="selectChannel"
                    :type="form.msgtype"
                    @success="handleActivityInsert"
                  />
                  <template
                    v-if="[0, 1, 3, 6, 7].includes(selectChannel.platform)"
                  >
                    <el-button
                      type="text"
                      size="mini"
                      class="button-mini"
                      @click="checkCoinStatus"
                    >插入书币
                    </el-button
                    >
                  </template>
                </el-form-item>
                <el-form-item label="标题">
                  <el-input
                    v-model="newsForm.title"
                    type="textarea"
                    style="width: 300px; margin-right: 10px"
                  />
                  <el-button
                    type="text"
                    @click="(showTitleDialog = true), (insertInfoType = 2)"
                  >选择标题
                  </el-button
                  >
                </el-form-item>
                <el-form-item label="图片">
                  <div
                    @click="showPicDialog = true"
                    v-if="!newsForm.picurl"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      font-size: 30px;
                      width: 300px;
                      height: 100px;
                      margin-right: 10px;
                      border: 1px solid #dcdfe6;
                      border-radius: 4px;
                      cursor: pointer;
                    "
                  >
                    <div><i class="el-icon-plus"></i></div>
                  </div>

                  <img
                    @click="showPicDialog = true"
                    v-if="newsForm.picurl"
                    :src="newsForm.picurl"
                    style="
                      width: 300px;
                      height: 100px;
                      margin-right: 10px;
                      cursor: pointer;
                    "
                  />
                </el-form-item>
                <el-form-item label="描述">
                  <el-input
                    type="textarea"
                    v-model="newsForm.description"
                    style="width: 300px; margin-right: 10px"
                  />
                  <el-button type="text" @click="showDescDialog = true"
                  >选择描述
                  </el-button
                  >
                </el-form-item>
                <el-form-item>
                  <div class="example">
                    <div class="card">
                      <p class="mesg-title">
                        {{ newsForm.title || "消息标题" }}
                      </p>
                      <div class="detail">
                        <p>{{ newsForm.description || "描述文字" }}</p>
                        <template>
                          <img
                            :src="newsForm.picurl"
                            alt=""
                            v-if="newsForm.picurl"
                          />
                          <div class="image-slot" v-else>
                            <i class="el-icon-picture-outline"></i>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </el-form>
              <div v-if="form.msgtype === 'voice'">
                <input
                  type="file"
                  ref="uploadFile"
                  style="display: none"
                  accept="audio/*"
                  @change="handleFileChange"
                />
                <span>{{
                    selectVoice.title ? selectVoice.title : ""
                  }}</span>
                <el-button
                  type="primary"
                  @click="handleChangeMiniPic(2)"
                >上传音频
                </el-button>
              </div>
              <div v-if="form.msgtype === 'image'">
                <input
                  type="file"
                  ref="uploadFile"
                  style="display: none"
                  accept="image/*"
                  @change="handleFileChange"
                />
                <div class="selectMiniPicContainer">
                  <div class="changeMiniPicType">
                    <el-radio v-model="selectMiniPicType" :label="1"
                    >选择素材
                    </el-radio
                    >
                    <el-radio
                      style="margin-left: 0"
                      v-model="selectMiniPicType"
                      :label="2"
                    >本地上传
                    </el-radio
                    >
                  </div>
                  <div
                    class="selectMiniPicBox"
                    v-loading="selectMiniImageLoading"
                  >
                    <div
                      @click="handleChangeMiniPic"
                      v-if="!selectImage.url"
                      style="
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          font-size: 30px;
                          width: 200px;
                          height: 100px;
                          margin-right: 10px;
                          border: 1px solid #dcdfe6;
                          border-radius: 4px;
                          cursor: pointer;
                        "
                    >
                      <div><i class="el-icon-plus"></i></div>
                    </div>
                    <img
                      @click="handleChangeMiniPic"
                      v-if="selectImage.url"
                      :src="selectImage.url"
                      style="
                          width: 200px;
                          height: 100px;
                          margin-right: 10px;
                          cursor: pointer;
                        "
                    />
                  </div>
                </div>
              </div>
              <div v-if="form.msgtype === 'miniprogrampage'">
                <input
                  type="file"
                  ref="uploadFile"
                  style="display: none"
                  accept="image/*"
                  @change="handleFileChange"
                />
                <el-form-item
                  v-loading="miniprogramLoading"
                  label="小程序封面"
                  class="necessary"
                >
                  <div class="selectMiniPicContainer">
                    <div class="changeMiniPicType">
                      <el-radio v-model="selectMiniPicType" :label="1"
                      >选择素材
                      </el-radio
                      >
                      <el-radio
                        style="margin-left: 0"
                        v-model="selectMiniPicType"
                        :label="2"
                      >本地上传
                      </el-radio
                      >
                    </div>
                    <div
                      class="selectMiniPicBox"
                      v-loading="selectMiniImageLoading"
                    >
                      <div
                        @click="handleChangeMiniPic"
                        v-if="!minForm.picurl"
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          font-size: 30px;
                          width: 200px;
                          height: 100px;
                          margin-right: 10px;
                          border: 1px solid #dcdfe6;
                          border-radius: 4px;
                          cursor: pointer;
                        "
                      >
                        <div><i class="el-icon-plus"></i></div>
                      </div>
                      <img
                        @click="handleChangeMiniPic"
                        v-if="minForm.picurl"
                        :src="minForm.picurl"
                        style="
                          width: 200px;
                          height: 100px;
                          margin-right: 10px;
                          cursor: pointer;
                        "
                      />
                    </div>
                  </div>
                </el-form-item>
                <el-form-item label="标题" class="necessary">
                  <el-input
                    type="text"
                    v-model="minForm.title"
                    style="width: 300px; margin-right: 10px"
                  />
                </el-form-item>
                <el-form-item label="appid" class="necessary">
                  <el-input
                    type="text"
                    v-model="minForm.appid"
                    style="width: 300px; margin-right: 10px"
                  />
                </el-form-item>
                <el-form-item label="页面地址" class="necessary">
                  <el-input
                    type="text"
                    v-model="minForm.pagepath"
                    style="width: 300px; margin-right: 10px"
                  />
                </el-form-item>
              </div>
            </el-form-item>
            <el-form-item
              label="预约发送："
              style="margin-bottom: 14px">
              <el-date-picker
                v-model="form.tick"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                style="width: 500px"
                placeholder="选择时间"
              >
              </el-date-picker>
            </el-form-item>
            <!-- 当天统计信息 -->
            <el-form-item label="接收用户" style="margin-bottom: 14px">
              <el-radio-group
                v-model="form.sendAll"
                @change="estimateNum = null"
                size="medium"
              >
                <el-radio-button :label="1">全部用户</el-radio-button>
                <el-radio-button :label="0">标签用户</el-radio-button>
              </el-radio-group>
              <span style="margin-left: 10px">关注过滤:</span>
              <el-input-number
                style="width: 160px; margin-left: 10px"
                v-model="subscribeHours"
                :min="0"
                placeholder="关注过滤"
              ></el-input-number>
              <el-popover placement="right" trigger="hover">
                <span>过滤关注时长用户,单位小时,0表示不过滤</span>
                <i
                  slot="reference"
                  style="margin-left: 10px"
                  class="el-icon-question"
                ></i>
              </el-popover>
            </el-form-item>
            <el-form-item label="测试人" style="margin-bottom: 14px">
              <el-select
                v-model="testId"
                filterable
                remote
                reserve-keyword
                placeholder="请输入用户名"
                :remote-method="userQuery"
                :loading="remoteLoading"
                @change="handleTestChange"
              >
                <el-option
                  v-for="item in testUser"
                  :key="item.openId"
                  :label="item.nickname"
                  :value="item.openId"
                >
                  <div class="row">
                    <user-avatar :info="item" name-prop="nickname" :size="25"/>
                    <span style="margin-left: 10px">{{ item.nickname }}</span>
                  </div>
                </el-option>
              </el-select>
              <el-button
                style="margin-left: 10px"
                type="primary"
                :loading="loadingButton"
                @click="handleTest"
              >发送
              </el-button
              >
            </el-form-item>
          </div>
          <div class="form-body-right">
            <el-form label-width="120px">
              <el-form label-width="85px">
                <!-- 基础属性新增 -->
                <!-- 基础属性新增结束 -->
                <el-form-item label="充值情况">
                  <el-radio-group v-model="form.rechargeType" size="medium">
                    <el-radio-button :label="0">不限</el-radio-button>
                    <el-radio-button :label="1">未充值</el-radio-button>
                    <el-radio-button :label="2">已充值</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <div class="inner-form" v-if="form.rechargeType === 2">
                  <el-form-item label="充值间隔" v-if="form.rechargeType === 2">
                    <el-input-number
                      style="width: 165px"
                      v-model="form.rechargeTimeSpaceStart"
                      :step-strictly="true"
                      :step="1"
                      placeholder="开始天数"
                      controls-position="right"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      v-model="form.rechargeTimeSpaceEnd"
                      :step-strictly="true"
                      :step="1"
                      placeholder="结束天数"
                      controls-position="right"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                  <!-- 阳光书城 掌中云天机剩余书币 -->
                  <el-form-item
                    label="剩余书币"
                    v-if="
                      form.rechargeType === 2 &&
                      (selectChannel.platform === 3 ||
                        selectChannel.platform === 0)
                    "
                  >
                    <el-input-number
                      style="width: 165px"
                      v-model="form.minKanDian"
                      placeholder="最小剩余书币"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      v-model="form.maxKanDian"
                      placeholder="最大剩余书币"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item
                    label="书币消耗率"
                    v-if="
                      form.rechargeType === 2 &&
                      [0, 3].includes(selectChannel.platform)
                    "
                  >
                    <el-input-number
                      style="width: 165px"
                      v-model="form.minKanDianRate"
                      placeholder="最小书币消耗率"
                      :precision="2"
                      :min="0"
                      :step="1"
                    >
                    </el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      v-model="form.maxKanDianRate"
                      placeholder="最大书币消耗率"
                      :precision="2"
                      :min="0"
                      :step="1"
                    >
                    </el-input-number>
                  </el-form-item>
                  <el-form-item label="累计充值" v-if="form.rechargeType === 2">
                    <el-input-number
                      style="width: 165px"
                      v-model="form.minTotalRecharge"
                      placeholder="最小充值"
                      :precision="2"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      placeholder="最大充值"
                      v-model="form.maxTotalRecharge"
                      :precision="2"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item label="最后充值" v-if="form.rechargeType === 2">
                    <el-input-number
                      style="width: 165px"
                      v-model="form.minLastRechargeAmt"
                      placeholder="最小充值"
                      :precision="2"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      placeholder="最大充值"
                      v-model="form.maxLastRechargeAmt"
                      :precision="2"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>
                  <el-form-item label="充值次数" v-if="form.rechargeType === 2">
                    <el-input-number
                      style="width: 165px"
                      v-model="form.minRechargeCount"
                      placeholder="最小次数"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      v-model="form.maxRechargeCount"
                      placeholder="最大次数"
                      :step="1"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>

                  <!-- 统计属性 -->
                  <!-- 最近7日价值 -->
                  <el-form-item
                    label="近7日充值"
                    v-if="form.rechargeType === 2"
                  >
                    <el-input-number
                      style="width: 165px"
                      v-model="form.minSevenRecharge"
                      placeholder="最小充值"
                      :min="0"
                    ></el-input-number>
                    <span style="margin: 0 10px"></span>
                    <el-input-number
                      style="width: 165px"
                      v-model="form.maxSevenRecharge"
                      placeholder="最大充值"
                      :min="0"
                    ></el-input-number>
                  </el-form-item>

                  <div class="vip-consume">
                    <!-- 是否vip -->
                    <el-form-item
                      style="width: 220px"
                      label="VIP"
                      label-width="45px"
                      v-if="form.rechargeType === 2"
                    >
                      <el-radio-group v-model="form.isVip">
                        <el-radio :label="2">不限</el-radio>
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <!-- 连续三周均有充值 -->

                    <div class="consume-box" style="width: 60%">
                      <el-tooltip
                        style="margin-left: 20px"
                        class="item"
                        effect="dark"
                        content="用户最近三周内每周均有充值"
                        placement="left"
                      >
                        <i class="el-icon-question"></i>
                      </el-tooltip>
                      <el-form-item
                        label-width="80px"
                        label="连续付费"
                        v-if="form.rechargeType === 2"
                      >
                        <el-radio-group v-model="form.continuousRecharge">
                          <el-radio :label="0">不限</el-radio>
                          <el-radio :label="1">是</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </div>
                  </div>
                  <!-- rfm -->
                  <div class="rfm-box">
                    <el-popover placement="right" width="500" trigger="hover">
                      <el-table :data="gridData" height="300">
                        <el-table-column
                          width="120"
                          property="label"
                          label="客户标签"
                        ></el-table-column>
                        <el-table-column
                          width="36"
                          property="R"
                          label="R"
                        ></el-table-column>
                        <el-table-column
                          width="36"
                          property="F"
                          label="F"
                        ></el-table-column>
                        <el-table-column
                          width="36"
                          property="M"
                          label="M"
                        ></el-table-column>
                        <el-table-column
                          property="scene"
                          label="场景"
                        ></el-table-column>
                        <el-table-column
                          width="50"
                          property="level"
                          label="等级"
                        ></el-table-column>
                      </el-table>
                      <i
                        slot="reference"
                        style="margin-left: 20px"
                        class="el-icon-question"
                      ></i>
                    </el-popover>
                    <el-form-item
                      label="RFM"
                      label-width="60px"
                      v-if="form.rechargeType === 2"
                    >
                      <el-select
                        v-model="form.rfm"
                        style="width: 350px"
                        placeholder="请选择rfm"
                        multiple
                        clearable
                      >
                        <el-option label="A类" :value="1"></el-option>
                        <el-option label="B类" :value="2"></el-option>
                        <el-option label="C类" :value="3"></el-option>
                      </el-select>
                      <!-- 解释表格 -->
                    </el-form-item>
                  </div>
                  <!-- 剩余优惠券 -->
                  <el-form-item
                    label="优惠敏感度"
                    class="add-element"
                    v-if="form.rechargeType === 2"
                  >
                    <el-select
                      style="width: 350px"
                      v-model="form.couponLevel"
                      placeholder="请选择优惠券级别"
                    >
                      <el-option label="不限" :value="0"></el-option>
                      <el-option label="1级" :value="1"></el-option>
                      <el-option label="2级" :value="2"></el-option>
                      <el-option label="3级" :value="3"></el-option>
                      <el-option label="4级" :value="4"></el-option>
                      <el-option label="5级" :value="5"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="书籍" v-if="form.rechargeType === 2">
                    <el-select
                      v-model="form.bookId"
                      filterable
                      remote
                      style="width: 350px"
                      reserve-keyword
                      clearable
                      placeholder="请输入书籍名称"
                      :remote-method="getBooks"
                      :loading="loading"
                    >
                      <el-option
                        v-if="defaultBook"
                        :label="defaultBook.bookName"
                        :value="defaultBook.id"
                      ></el-option>
                      <el-option
                        v-for="item in bookList"
                        :key="item.id"
                        :label="item.bookName"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-form>
              <template>
                <el-form-item label-width="90px" label="性别">
                  <el-radio-group v-model="form.sex">
                    <el-radio :label="0">不限</el-radio>
                    <el-radio :label="1">男</el-radio>
                    <el-radio :label="2">女</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="所在地区" label-width="85px">
                  <el-select
                    size="small"
                    style="width: 100px"
                    v-model="form.province"
                    filterable
                    placeholder="省份"
                    clearable
                  >
                    <el-option
                      v-for="item in area"
                      :key="item.cid"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <el-select
                    size="small"
                    style="width: 100px"
                    v-model="form.city"
                    filterable
                    placeholder="城市"
                    clearable
                  >
                    <el-option
                      v-for="item in areaCity"
                      :key="item.cid"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="关注时间" label-width="85px">
                  <el-date-picker
                    v-model="form.startDate"
                    align="right"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 180px"
                    placeholder="开始时间"
                    :picker-options="pickerOptions"
                  >
                  </el-date-picker>
                  <el-date-picker
                    v-model="form.endDate"
                    align="right"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 180px; margin-left: 10px"
                    placeholder="结束时间"
                    :picker-options="pickerOptions"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="签到次数" label-width="85px">
                  <el-input-number
                    style="width: 165px"
                    v-model="form.minSignInCount"
                    placeholder="最小签到次数"
                    :min="0"
                    :step="1"
                  >
                  </el-input-number>
                  <span style="margin: 0 10px"></span>

                  <el-input-number
                    style="width: 165px"
                    v-model="form.maxSignInCount"
                    placeholder="最大签到次数"
                    :min="0"
                    :step="1"
                  >
                  </el-input-number>
                </el-form-item>
              </template>
            </el-form>
            <div class="form-body-right-mask" v-if="form.sendAll !== 0">
              <p>仅标签用户可操作</p>
            </div>
          </div>
        </div>
      </el-form>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <div class="sub-box">
        <div class="button-box">
          <el-button @click="handleClose">取 消</el-button>
          <el-button
            style="margin-left: 10px"
            type="primary"
            @click="handleSubmit"
            :loading="submitLoading"
          >确定</el-button
          >
        </div>
      </div>
    </span>
    <select-title
      :show="showTitleDialog"
      :classifyList="classifyList"
      :insertInfoType="insertInfoType"
      @close="showTitleDialog = false"
      @success="handleInsertBookTitle"
    />
    <select-image
      :show="showPicDialog"
      :classifyList="classifyList"
      @close="showPicDialog = false"
      @success="handleSelectImageSuccess"
    />
    <select-description
      :show="showDescDialog"
      :classify-list="classifyList"
      @close="showDescDialog = false"
      @success="(description) => (newsForm.description = description)"
    />
    <el-dialog
      title="插入小程序"
      width="500px"
      :visible.sync="showMiniDialog"
      :append-to-body="true"
    >
      <insert-mini-program
        :show="showMiniDialog"
        @close="showMiniDialog = false"
        @success="handleInsert"
      />
    </el-dialog>
    <InsertTemplate
      :classifyList="classifyList"
      :show="showTemplate"
      @close="showTemplate = false"
      @handleOk="handleInsertTemplate"
    ></InsertTemplate>
    <!-- 插入书币活动 -->
    <insert-book-coin
      :show="showBookCoin"
      :channel="selectChannel"
      :type="form.msgtype"
      @close="showBookCoin = false"
      @success="handleBookCoinInsert"
    ></insert-book-coin>
  </el-dialog>
</template>
<script>
import $ from "jquery";
import jQuery from "jquery";
import area from "@/assets/js/area.js";
import InsertBook from "../../service/insertBook/index";
import InsertPage from "../../service/insertPage/index";
import InsertActivity from "../../service/insertActivity/index";
import SelectTitle from "../../service/components/selectTitle";
import SelectImage from "../../service/components/selectPic";
import SelectDescription from "../../service/components/selectDescription";
import InsertMiniProgram from "../../service/components/insertMiniProgram";
import InsertTemplate from "../../service/components/insertTemplate";
import InsertBookCoin from "../../service/insertBookCoin";
import {getBookList} from "../../../api/account";
import {
  addCustomerBatch,
  checkPlatform,
  getAccountUser,
  getCustomerBatchDetail,
  platformLocalSelectUpload,
  platformLocalUpload,
  testSend,
  updateCustomerBatch,
} from "@/api/service";
import UserAvatar from "@/components/UserAvatar";
import {messagePickerOptions, messagePlatformListDetail} from "@/assets/js/options";
import {mapGetters} from "vuex";
import {validActive} from "@utils";
import {uploadFile} from "@/api/common";

const defaultForm = {
  msgtype: "text",
  sendAll: 1,
  continuousRecharge: 0,
  sex: 0,
  rechargeType: 2,
  isVip: 2,
  rfm: 0,
  couponLevel: 0,
  bookId: null,
  startDate: null,
  endDate: null,
  remark: "",
  novelName: "",
};
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    classifyList: {
      type: Array,
      default: () => [],
    },
    id: Number
  },
  data() {
    return {
      messagePlatformListDetail,
      platform: [],
      categoryId: [],
      testId: null,
      str: "",
      estimateNum: null,
      form: {},
      textStr: "", // 文本内容
      textIndex: 0,
      newsForm: {
        picurl: "",
        url: "",
        description: "",
        title: "",
      },
      minForm: {
        picurl: "",
        pagepath: "",
        thumb_media_id: "",
        appid: "",
        title: "",
      },
      showTitleDialog: false,
      showPicDialog: false,
      showDescDialog: false,
      showVoiceDialog: false,
      showImageDialog: false,
      showVideoDialog: false,
      showMiniDialog: false,
      showTemplate: false, // 插入模板
      pickerOptions: messagePickerOptions,
      bookList: [],
      loading: false,
      formLoading: false,
      defaultBook: null,
      submitLoading: false,
      selectVoice: {
        url: "",
        title: "",
        mediaId: "",
      },
      selectImage: {
        url: "",
        title: "",
        mediaId: "",
      }, // 选择图片模块
      selectChannel: {}, // 选中公众号
      testUser: [],
      remoteLoading: false,
      insertInfoType: 1, // 判断是在文本还是在图片的消息类型中插入标题(1是文本，2是图片)
      textMaskConten: "",
      timeoutObj: {
        // 针对延迟回复
        hour: 0,
        minute: 0,
        second: 0,
      },
      // 新增客户信息相关
      area,
      areaCity: [],
      gridData: [
        {
          label: "重要价值客户",
          R: "高",
          F: "高",
          M: "高",
          scene: "最近充值-高频次-高金额",
          level: "A类",
        },
        {
          label: "重要深耕客户",
          R: "高",
          F: "低",
          M: "高",
          scene: "最近充值-低频次-高金额",
          level: "A类",
        },
        {
          label: "重要唤回客户",
          R: "低",
          F: "高",
          M: "高",
          scene: "最近未充-高频次-高金额",
          level: "B类",
        },
        {
          label: "重要挽留客户",
          R: "低",
          F: "低",
          M: "高",
          scene: "最近未充-低频次-高金额",
          level: "B类",
        },
        {
          label: "潜力客户",
          R: "高",
          F: "高",
          M: "低",
          scene: "最近充值-高频次-低金额",
          level: "B类",
        },
        {
          label: "一般发展客户",
          R: "高",
          F: "低",
          M: "低",
          scene: "最近充值-低频次-低金额",
          level: "B类",
        },
        {
          label: "一般维持客户",
          R: "低",
          F: "高",
          M: "低",
          scene: "最近未充-高频次-低金额",
          level: "B类",
        },
        {
          label: "流失客户",
          R: "低",
          F: "低",
          M: "低",
          scene: "最近未充-低频次-低金额",
          level: "C类",
        },
      ],
      showDetail: false,
      loadingButton: false,
      // 过滤时长
      subscribeHours: 5,
      showBookCoin: false,
      miniprogramLoading: false,
      // 小程序卡片选择改版
      selectMiniImageLoading: false,
      selectMiniPicType: 1,
    };
  },
  watch: {
    show(newVal) {
      if (newVal) {
        if (sessionStorage.getItem("testNickName")) {
          this.userQuery(sessionStorage.getItem("testNickName"));
          this.testId = sessionStorage.getItem("testId");
        } else {
          this.testId = null;
        }
        this.estimateNum = null;
        if (this.id) {
          this.initData();
        } else {
          this.subscribeHours = 5;
          this.form = {...defaultForm};

          this.newsForm = {
            picurl: "",
            url: "",
            description: "",
            title: "",
          };
          this.timeoutObj = {
            hour: 0,
            minute: 0,
            second: 0,
          };
          this.platform = [];
          this.categoryId = [];
          this.selectVoice = {
            url: "",
            title: "",
          };
          this.selectImage = {
            url: "",
            title: "",
          };
          this.textStr = "";
          if (this.platform.length !== 0 && (this.form.msgtype === 'text' || this.form.msgtype === 'news')) {
            this.handlePlatformChange(this.platform[0]);
          } else {
            this.selectChannel = {};
          }
        }
      } else {
        if ($(".message-box .el-textarea .el-textarea__inner").length > 0) {
          $(
            ".message-box .el-textarea .el-textarea__inner"
          ).highlightWithinTextarea("destory");
        }
      }
    },
    "form.msgtype": {
      handler(newV) {
        if (newV === "text") {
          this.initHighlightTextarea();
        }
      },
    },
    "form.province": {
      handler(newValue, oldValue) {
        this.area.forEach((item) => {
          if (item.name === this.form.province) {
            this.areaCity = item.children;
          }
        });
        if (newValue === "") {
          this.areaCity = [];
        }
        if (oldValue !== undefined) {
          this.$set(this.form, "city", null);
        }
      },
    },
  },
  computed: {
    ...mapGetters(["officialList"]),
  },
  mounted() {
    getBookList({page: 1, pageSize: 50}).then((res) => {
      this.bookList = res.list;
    });
    this.extendJQuery();
  },
  methods: {
    selectAccountChannel() {
      if (this.form.msgtype === 'text' || this.form.msgtype === 'news') {
        if (this.categoryId.length > 1 || this.platform.length > 1) {
          return this.$message.error("平台或标签只能选择一个！");
        }
        if (this.platform.length !== 0) {
          this.handlePlatformChange(this.platform[0]);
          this.userQuery();
        }
        if (this.categoryId.length !== 0 && this.platform.length === 0) {
          checkPlatform({categoryId: this.categoryId[0]}).then(res => {
            const parse = JSON.parse(JSON.stringify(res));
            if (parse.length === 0) {
              return this.$message.error("该标签不存在公众号！");
            }
            if (parse.length > 1) {
              return this.$message.error("该标签存在多个平台！");
            }
            this.handlePlatformChange(parse[0]);
            this.userQuery();
          })
        }
      } else {
        if (this.platform.length !== 0) {
          this.handlePlatformChange(this.platform[0]);
          this.userQuery();
        }
        if (this.categoryId.length !== 0 && this.platform.length === 0) {
          checkPlatform({categoryId: this.categoryId[0]}).then(res => {
            const parse = JSON.parse(JSON.stringify(res));
            if (parse.length === 0) {
              return this.$message.error("该标签不存在公众号！");
            }
            this.handlePlatformChange(parse[0]);
            this.userQuery();
          })
        }
      }
    },
    handlePlatformChange(platform) {
      // * 找到一个即可
      const account = this.officialList.find(
        (item) => item.platform === platform
      );
      if (account) {
        this.selectChannel = {
          id: account.id,
          channelName: account.channelName,
          platform,
        };
      }
    },
    handleSelectImageSuccess(data) {
      if (!this.selectChannel.id) {
        this.$message.warning("请选择平台或标签!");
        return false;
      }
      if (this.form.msgtype === 'news') {
        this.newsForm.picurl = data;
      }
      if (this.form.msgtype === 'image') {
        // 上传微信
        platformLocalSelectUpload({
          accountChannelId: this.selectChannel.id,
          url: data,
          type: "image",
        }).then((res) => {
          this.selectImage.mediaId = res.media_id;
        })
        this.selectImage.url = data
      }
      if (this.form.msgtype === 'miniprogrampage') {
        // 上传微信
        platformLocalSelectUpload({
          accountChannelId: this.selectChannel.id,
          url: data,
          type: "thumb",
        }).then((res) => {
          this.minForm.thumb_media_id = res.media_id;
        })
        this.minForm.picurl = data
      }
    },
    handleChangeMiniPic(value) {
      if (value === 2) {
        this.selectMiniPicType = value
      }
      if (this.selectMiniPicType === 2) {
        this.$refs.uploadFile.click();
      } else {
        this.showPicDialog = true;
      }
    },
    handleFileChange(e) {
      if (!this.selectChannel.id) {
        this.$message.warning("请选择平台或标签!");
        return false;
      }
      this.miniprogramLoading = true
      const targetFile = e.target.files[0]
      // 上传到微信
      const file = new FormData();
      file.append("file", targetFile);
      if (this.form.msgtype === 'voice') {
        // 上传到七牛云
        uploadFile({file: targetFile}).then((res) => {
          this.selectVoice.url = res;
          this.selectVoice.title = targetFile.name;
          platformLocalUpload(this.selectChannel.id, "voice", file)
            .then((res) => {
              this.selectVoice.mediaId = res.media_id;
            })
        })
      }
      if (this.form.msgtype === 'image') {
        this.selectImage.title = targetFile.name;
        platformLocalUpload(this.selectChannel.id, "image", file)
          .then((res) => {
            this.selectImage.url = res.url;
            this.selectImage.mediaId = res.media_id;
          })
      }
      if (this.form.msgtype === 'miniprogrampage') {
        platformLocalUpload(this.selectChannel.id, "thumb", file)
          .then((res) => {
            this.minForm.picurl = res.url;
            this.minForm.thumb_media_id = res.media_id;
          })
      }
      this.$refs.uploadFile.value = ""
      this.miniprogramLoading = false
    },
    // 缓存发送人
    handleTestChange(item) {
      let nickName = null;
      this.testUser.forEach((element) => {
        if (element.openId === item) {
          nickName = element.nickname;
        }
      });
      sessionStorage.setItem("testNickName", nickName);
      sessionStorage.setItem("testId", item);
    },
    // 关闭详情
    closeDetail() {
      this.showDetail = false;
    },
    initHighlightTextarea() {
      this.$nextTick(() => {
        // const regExp = /^(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/
        setTimeout(() => {
          $("textarea")
            .each(function () {
              this.setAttribute(
                "style",
                "height:" + this.scrollHeight + "px;overflow-y:hidden;"
              );
            })
            .on("input", function () {
              this.style.height = "auto";
              this.style.height = this.scrollHeight + "px";
            });
        }, 50);

        $(
          ".message-box .el-textarea .el-textarea__inner"
        ).highlightWithinTextarea({
          // highlight: /(<a.(href="|href=')(((ht|f)tps?):\/\/)[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])("?|'?)>.[^<\/>)]*<\/a>)/g
          // highlight: /<a.(href="|href=')(((ht|f)tps?):\/\/)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?>.*<\/a>/g
          highlight:
            /(<a.(href="|href=')(((ht|f)tps?):\/\/)[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])("?|'?)>.*<\/a>)/g,
        });
      });
    },
    userQuery(name) {
      if (this.selectChannel.id) {
        this.remoteLoading = true;
        const nickName = sessionStorage.getItem("testNickName");
        getAccountUser(this.selectChannel.id, name ? name : nickName ? nickName : null)
          .then((res) => {
            this.testUser = res;
          })
          .finally(() => (this.remoteLoading = false));
      }
    },
    initData() {
      this.formLoading = true;
      getCustomerBatchDetail(this.id)
        .then((res) => {
          const {
            id,
            content,
            novelName,
            categoryId,
            platform,
            ...other
          } = res;
          if (platform) {
            this.platform = platform.split(",").map((res) => Number(res))
            this.handlePlatformChange(this.platform[0]);
            this.userQuery();
          }
          if (categoryId) {
            this.categoryId = categoryId.split(",").map((res) => Number(res))
            // 没有平台是加载
            if (!platform) {
              checkPlatform({categoryId: this.categoryId[0]}).then(res => {
                const parse = JSON.parse(JSON.stringify(res));
                if (parse.length !== 0) {
                  this.handlePlatformChange(parse[0]);
                  this.userQuery();
                } else {
                  this.selectChannel = {};
                }
              })
            }
          }
          this.subscribeHours = other.subscribeHours || 0;
          this.id = id;
          this.form = other;
          this.form.rfm =
            other.rfm === "0" || !other.rfm
              ? []
              : other.rfm.split(",").map((item) => Number(item));

          const contentObj = JSON.parse(content);
          if (other.bookId) {
            this.defaultBook = {
              bookName: other.bookName,
              id: other.bookId,
            };
          }
          if (other.msgtype === "text") {
            this.textStr = contentObj.content;
          } else if (other.msgtype === "news") {
            this.$set(this.form, "novelName", novelName);
            this.newsForm = contentObj.articles[0];
          } else if (other.msgtype === "voice") {
            const json = JSON.parse(novelName);
            this.selectVoice = {
              mediaId: contentObj.media_id,
              url: json.url,
              title: json.title,
            };
          } else if (other.msgtype === "image") {
            const json = JSON.parse(novelName);
            this.selectImage = {
              mediaId: contentObj.media_id,
              title: json.title,
              url: json.url,
            };
          } else if (other.msgtype === "miniprogrampage") {
            this.minForm = {
              picurl: novelName,
              title: contentObj.title,
              appid: contentObj.appid,
              pagepath: contentObj.pagepath,
              thumb_media_id: contentObj.thumb_media_id,
            };
          }
        })
        .finally(() => (this.formLoading = false));
    },
    handleTest() {
      const {msgtype, remark} = this.form;
      const {
        textStr,
        newsForm,
        testId,
        selectVoice,
        selectImage,
        selectChannel,
      } = this;
      if (!remark) {
        this.$message.error("请输入备注");
        return false;
      }
      if (!selectChannel) {
        this.$message.error("请选择平台或标签！");
        return false;
      }
      if (msgtype === "text" && !textStr) {
        this.$message.error("请输入发送内容");
        return false;
      }
      if (msgtype === "voice" && !selectVoice.url) {
        this.$message.error("请选择音频素材");
        return false;
      }
      if (msgtype === "image" && !selectImage.url) {
        this.$message.error("请选择图片素材");
        return false;
      }
      if (msgtype === "news" && !newsForm.url) {
        this.$message.error("请选择图文链接");
        return false;
      }
      // 小程序卡片
      if (msgtype === "miniprogrampage") {
        if (!this.minForm.thumb_media_id) {
          return this.$message.error("请选择小程序封面");
        }
        if (!this.minForm.title) {
          return this.$message.error("请填写标题");
        }
        if (!this.minForm.appid) {
          return this.$message.error("请填写appid");
        }
        if (!this.minForm.pagepath) {
          return this.$message.error("请填写pagepath");
        }
      }
      if (!testId) {
        this.$message.error("请输入测试openid");
        return false;
      }
      const params = {
        accountChannelId: selectChannel.id,
        msgType: msgtype,
        openId: testId,
        remark,
      };
      params.subscribeHours = this.subscribeHours; //新增排除时长
      if (msgtype === "text") {
        params.content = JSON.stringify({content: textStr});
      } else if (msgtype === "news") {
        params.content = JSON.stringify({
          articles: [
            {
              picurl: newsForm.picurl,
              url: newsForm.url,
              description: newsForm.description,
              title: newsForm.title,
            },
          ],
        });
      } else if (msgtype === "voice") {
        params.content = JSON.stringify({
          media_id: selectVoice.mediaId,
        });
        params.novelName = selectVoice.title;
      } else if (msgtype === "image") {
        params.content = JSON.stringify({
          media_id: selectImage.mediaId,
        });
        params.novelName = selectImage.title;
      } else if (msgtype === "miniprogrampage") {
        params.content = JSON.stringify({
          title: this.minForm.title,
          appid: this.minForm.appid,
          pagepath: this.minForm.pagepath,
          thumb_media_id: this.minForm.thumb_media_id,
        });
        params.novelName = this.minForm.picurl;
      }
      this.loadingButton = true;
      testSend(params)
        .then(() => {
          this.$message.success("发送成功");
        })
        .finally(() => {
          this.loadingButton = false;
        });
    },
    handleSubmit() {
      const {
        textStr,
        newsForm,
        form,
        selectVoice,
        selectImage,
        selectChannel,
        platform,
        categoryId,
      } = this;
      const {msgtype, tick, remark} = form;
      if (!remark) {
        this.$message.error("请输入备注");
        return false;
      }
      if (platform.length === 0 && categoryId.length === 0) {
        this.$message.error("平台与标签不能同时为空");
        return false;
      }
      // 判定平台
      if (msgtype === 'text' || msgtype === 'news') {
        if (platform.length > 1 || categoryId.length > 1) {
          this.$message.error("平台或标签只能选择一个！");
          return false;
        }
      }
      if (new Date(tick).getTime() < Date.now()) {
        this.$message.error("预约发送时间不能小于当前时间");
        return false;
      }
      if (msgtype === "text" && !textStr) {
        this.$message.error("请输入发送内容");
        return false;
      }
      if (msgtype === "voice" && !selectVoice.mediaId) {
        this.$message.error("请选择音频素材");
        return false;
      }
      if (msgtype === "image" && !selectImage.mediaId) {
        this.$message.error("请选择图片素材");
      }
      if (msgtype === "news" && !newsForm.url) {
        this.$message.error("请选择图文链接");
        return false;
      }
      // 小程序卡片
      if (msgtype === "miniprogrampage") {
        if (!this.minForm.picurl) {
          return this.$message.error("请选择小程序封面");
        }
        if (!this.minForm.title) {
          return this.$message.error("请填写标题");
        }
        if (!this.minForm.appid) {
          return this.$message.error("请填写appid");
        }
        if (!this.minForm.pagepath) {
          return this.$message.error("请填写页面地址");
        }
      }
      if (validActive(msgtype, selectChannel, textStr, newsForm)) {
        this.$confirm(
          selectChannel.channelName + "设置了9.9元链接，是否确认保存?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          this.save();
        }).catch(() => {
          this.submitLoading = false
        });
      } else {
        this.save();
      }
    },
    save() {
      const {
        id,
        textStr,
        newsForm,
        form,
        selectVoice,
        selectImage,
        platform,
        categoryId,
      } = this;
      const {msgtype, tick} = form;
      const params = {
        ...form,
        accountChannelId: this.selectChannel.id,
        rechargeTimeSpaceStart: this.form.rechargeTimeSpaceStart,
        platform: platform.join(','),
        categoryId: categoryId.join(',')
      };
      params.subscribeHours = this.subscribeHours; //新增排除时长
      if (msgtype === "text") {
        params.content = JSON.stringify({content: textStr});
      } else if (msgtype === "news") {
        params.content = JSON.stringify({
          articles: [
            {
              picurl: newsForm.picurl,
              url: newsForm.url,
              description: newsForm.description,
              title: newsForm.title,
            },
          ],
        });
      } else if (msgtype === "voice") {
        params.content = JSON.stringify({
          media_id: selectVoice.mediaId,
        });
        params.novelName = JSON.stringify(selectVoice);
      } else if (msgtype === "image") {
        params.content = JSON.stringify({
          media_id: selectImage.mediaId,
        });
        params.novelName = JSON.stringify(selectImage);
      } else if (msgtype === "miniprogrampage") {
        params.content = JSON.stringify({
          title: this.minForm.title,
          appid: this.minForm.appid,
          pagepath: this.minForm.pagepath,
          thumb_media_id: this.minForm.thumb_media_id,
        });
        params.novelName = this.minForm.picurl;
      }
      this.submitLoading = true;
      params.rfm =
        Array.isArray(params.rfm) && params.rfm.length > 0
          ? params.rfm.join(",")
          : "0";
      if (id) {
        updateCustomerBatch(id, params).then(() => {
          this.$message.success("修改成功");
          this.$emit("close");
          this.$emit("refresh");
        }).finally(() => (this.submitLoading = false))
          .catch(() => {
            this.submitLoading = false
          })
      } else {
        addCustomerBatch(params).then(() => {
          this.$message.success("保存成功");
          this.$emit("close");
          this.$emit("refresh");
        }).finally(() => (this.submitLoading = false))
          .catch(() => {
            this.submitLoading = false
          })
      }
    },
    getBooks(query) {
      this.defaultBook = null;
      getBookList({page: 1, pageSize: 50, bookName: query}).then((res) => {
        this.bookList = res.list;
      });
    },
    handleInsertBookTitle(title) {
      this.insertInfoType === 1
        ? this.handleInsert(title)
        : (this.newsForm.title = title);
    },
    handleDialogShow(prop) {
      if (this.selectChannel) {
        this[prop] = true;
      } else {
        this.$message.warning("请先选择公众号！");
      }
    },
    // 对文本当中的html进行转义
    HTMLEncode(html) {
      var temp = document.createElement("div");
      temp.textContent != null
        ? (temp.textContent = html)
        : (temp.innerText = html);
      var output = temp.innerHTML;
      temp = null;
      return output;
    },
    handleBlur(event) {
      this.textIndex = event.srcElement.selectionStart;
    },
    handleActivityInsert(data) {
      if (this.form.msgtype === "text") {
        this.handleInsert(data.join("\n"));
      } else {
        this.$set(this.form, 'novelName', data.name);
        this.newsForm.url = data.url;
      }
    },
    checkCoinStatus() {
      if (!this.selectChannel.id) return this.$message.error("请选平台或标签");
      this.showBookCoin = true;
    },
    handleBookCoinInsert(data) {
      if (this.form.msgtype === "text") {
        this.handleInsert(data.value);
      } else {
        this.$set(this.form, 'novelName', data.name);
        this.newsForm.url = data.url;
      }
    },
    handleSelectSuccess({link, name, url, bookTitle}) {
      if (this.form.msgtype === "text") {
        this.handleInsert(link);
      } else {
        this.$set(this.form, 'novelName', bookTitle ? `${bookTitle}${name}` : `${name}`)
        this.newsForm.url = url;
      }
    },
    handleInsert(str) {
      const {textStr, textIndex} = this;
      this.textStr =
        textStr.slice(0, textIndex) + str + textStr.slice(textIndex);
    },
    handleClose() {
      this.$confirm("确定要取消操作吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.selectChannel = {};
        this.platform = [];
        this.categoryId = [];
        this.$emit("close");
      });
    },
    // ! 插入模板内容
    handleInsertTemplate(val) {
      const {content} = val;
      this.handleInsert(content);
    },
    extendJQuery() {
      (function ($) {
        const ID = "hwt";

        const HighlightWithinTextarea = function ($el, config) {
          this.init($el, config);
        };

        HighlightWithinTextarea.prototype = {
          init: function ($el, config) {
            this.$el = $el;

            // backwards compatibility with v1 (deprecated)
            if (this.getType(config) === "function") {
              config = {highlight: config};
            }

            if (this.getType(config) === "custom") {
              this.highlight = config;
              this.generate();
            } else {
              console.error("valid config object not provided");
            }
          },

          // returns identifier strings that aren't necessarily "real" JavaScript types
          getType: function (instance) {
            const type = typeof instance;
            if (!instance) {
              return "falsey";
            } else if (Array.isArray(instance)) {
              if (
                instance.length === 2 &&
                typeof instance[0] === "number" &&
                typeof instance[1] === "number"
              ) {
                return "range";
              } else {
                return "array";
              }
            } else if (type === "object") {
              if (instance instanceof RegExp) {
                return "regexp";
                // } else if (instance.hasOwnProperty('highlight')) {
              } else if (
                Object.prototype.hasOwnProperty.call(instance, "highlight")
              ) {
                return "custom";
              }
            } else if (type === "function" || type === "string") {
              return type;
            }

            return "other";
          },

          generate: function () {
            this.$el
              .addClass(ID + "-input " + ID + "-content")
              .on("input." + ID, this.handleInput.bind(this))
              .on("scroll." + ID, this.handleScroll.bind(this));

            this.$highlights = $("<div>", {
              class: ID + "-highlights " + ID + "-content",
            });

            this.$backdrop = $("<div>", {class: ID + "-backdrop"}).append(
              this.$highlights
            );

            this.$container = $("<div>", {class: ID + "-container"})
              .insertAfter(this.$el)
              .append(this.$backdrop, this.$el) // moves $el into $container
              .on("scroll", this.blockContainerScroll.bind(this));

            this.browser = this.detectBrowser();
            switch (this.browser) {
              case "firefox":
                this.fixFirefox();
                break;
              case "ios":
                this.fixIOS();
                break;
            }

            // plugin function checks this for success
            this.isGenerated = true;

            // trigger input event to highlight any existing input
            this.handleInput();
          },

          // browser sniffing sucks, but there are browser-specific quirks to handle
          // that are not a matter of feature detection
          detectBrowser: function () {
            const ua = window.navigator.userAgent.toLowerCase();
            if (ua.indexOf("firefox") !== -1) {
              return "firefox";
            } else if (ua.match(/msie|trident\/7|edge/)) {
              return "ie";
            } else if (
              !!ua.match(/ipad|iphone|ipod/) &&
              ua.indexOf("windows phone") === -1
            ) {
              // Windows Phone flags itself as "like iPhone", thus the extra check
              return "ios";
            } else {
              return "other";
            }
          },

          // Firefox doesn't show text that scrolls into the padding of a textarea, so
          // rearrange a couple box models to make highlights behave the same way
          fixFirefox: function () {
            // take padding and border pixels from highlights div
            const padding = this.$highlights.css([
              "padding-top",
              "padding-right",
              "padding-bottom",
              "padding-left",
            ]);
            const border = this.$highlights.css([
              "border-top-width",
              "border-right-width",
              "border-bottom-width",
              "border-left-width",
            ]);
            this.$highlights.css({
              padding: "0",
              "border-width": "0",
            });

            this.$backdrop
              .css({
                // give padding pixels to backdrop div
                "margin-top": "+=" + padding["padding-top"],
                "margin-right": "+=" + padding["padding-right"],
                "margin-bottom": "+=" + padding["padding-bottom"],
                "margin-left": "+=" + padding["padding-left"],
              })
              .css({
                // give border pixels to backdrop div
                "margin-top": "+=" + border["border-top-width"],
                "margin-right": "+=" + border["border-right-width"],
                "margin-bottom": "+=" + border["border-bottom-width"],
                "margin-left": "+=" + border["border-left-width"],
              });
          },

          // iOS adds 3px of (unremovable) padding to the left and right of a textarea,
          // so adjust highlights div to match
          fixIOS: function () {
            this.$highlights.css({
              "padding-left": "+=3px",
              "padding-right": "+=3px",
            });
          },

          handleInput: function () {
            const input = this.$el.val();
            const ranges = this.getRanges(input, this.highlight);
            const unstaggeredRanges = this.removeStaggeredRanges(ranges);
            const boundaries = this.getBoundaries(unstaggeredRanges);
            this.renderMarks(boundaries);
          },

          getRanges: function (input, highlight) {
            const type = this.getType(highlight);
            switch (type) {
              case "array":
                return this.getArrayRanges(input, highlight);
              case "function":
                return this.getFunctionRanges(input, highlight);
              case "regexp":
                return this.getRegExpRanges(input, highlight);
              case "string":
                return this.getStringRanges(input, highlight);
              case "range":
                return this.getRangeRanges(input, highlight);
              case "custom":
                return this.getCustomRanges(input, highlight);
              default:
                if (!highlight) {
                  // do nothing for falsey values
                  return [];
                } else {
                  console.error("unrecognized highlight type");
                }
            }
          },

          getArrayRanges: function (input, arr) {
            const ranges = arr.map(this.getRanges.bind(this, input));
            return Array.prototype.concat.apply([], ranges);
          },

          getFunctionRanges: function (input, func) {
            return this.getRanges(input, func(input));
          },

          getRegExpRanges: function (input, regex) {
            const ranges = [];
            let match;
            while (((match = regex.exec(input)), match !== null)) {
              ranges.push([match.index, match.index + match[0].length]);
              if (!regex.global) {
                // non-global regexes do not increase lastIndex, causing an infinite loop,
                // but we can just break manually after the first match
                break;
              }
            }
            return ranges;
          },

          getStringRanges: function (input, str) {
            const ranges = [];
            const inputLower = input.toLowerCase();
            const strLower = str.toLowerCase();
            let index = 0;
            while (
              ((index = inputLower.indexOf(strLower, index)), index !== -1)
              ) {
              ranges.push([index, index + strLower.length]);
              index += strLower.length;
            }
            return ranges;
          },

          getRangeRanges: function (input, range) {
            return [range];
          },

          getCustomRanges: function (input, custom) {
            const ranges = this.getRanges(input, custom.highlight);
            if (custom.className) {
              ranges.forEach(function (range) {
                // persist class name as a property of the array
                if (range.className) {
                  range.className = custom.className + " " + range.className;
                } else {
                  range.className = custom.className;
                }
              });
            }
            return ranges;
          },

          // prevent staggered overlaps (clean nesting is fine)
          removeStaggeredRanges: function (ranges) {
            const unstaggeredRanges = [];
            ranges.forEach(function (range) {
              const isStaggered = unstaggeredRanges.some(function (
                unstaggeredRange
              ) {
                const isStartInside =
                  range[0] > unstaggeredRange[0] &&
                  range[0] < unstaggeredRange[1];
                const isStopInside =
                  range[1] > unstaggeredRange[0] &&
                  range[1] < unstaggeredRange[1];
                return isStartInside !== isStopInside; // xor
              });
              if (!isStaggered) {
                unstaggeredRanges.push(range);
              }
            });
            return unstaggeredRanges;
          },

          getBoundaries: function (ranges) {
            const boundaries = [];
            ranges.forEach(function (range) {
              boundaries.push({
                type: "start",
                index: range[0],
                className: range.className,
              });
              boundaries.push({
                type: "stop",
                index: range[1],
              });
            });

            this.sortBoundaries(boundaries);
            return boundaries;
          },

          sortBoundaries: function (boundaries) {
            // backwards sort (since marks are inserted right to left)
            boundaries.sort(function (a, b) {
              if (a.index !== b.index) {
                return b.index - a.index;
              } else if (a.type === "stop" && b.type === "start") {
                return 1;
              } else if (a.type === "start" && b.type === "stop") {
                return -1;
              } else {
                return 0;
              }
            });
          },

          renderMarks: function (boundaries) {
            let input = this.$el.val();
            boundaries.forEach(function (boundary, index) {
              let markup;
              if (boundary.type === "start") {
                markup = "{{hwt-mark-start|" + index + "}}";
              } else {
                markup = "{{hwt-mark-stop}}";
              }
              input =
                input.slice(0, boundary.index) +
                markup +
                input.slice(boundary.index);
            });

            // this keeps scrolling aligned when input ends with a newline
            input = input.replace(/\n(\{\{hwt-mark-stop\}\})?$/, "\n\n$1");

            // encode HTML entities
            input = input.replace(/</g, "&lt;").replace(/>/g, "&gt;");

            if (this.browser === "ie") {
              // IE/Edge wraps whitespace differently in a div vs textarea, this fixes it
              input = input.replace(/ /g, " <wbr>");
            }

            // replace start tokens with opening <mark> tags with class name
            input = input.replace(
              /\{\{hwt-mark-start\|(\d+)\}\}/g,
              function (match, submatch) {
                var className = boundaries[+submatch].className;
                if (className) {
                  return '<mark class="' + className + '">';
                } else {
                  return "<mark>";
                }
              }
            );

            // replace stop tokens with closing </mark> tags
            input = input.replace(/\{\{hwt-mark-stop\}\}/g, "</mark>");

            this.$highlights.html(input);
          },

          handleScroll: function () {
            const scrollTop = this.$el.scrollTop();
            this.$backdrop.scrollTop(scrollTop);

            // Chrome and Safari won't break long strings of spaces, which can cause
            // horizontal scrolling, this compensates by shifting highlights by the
            // horizontally scrolled amount to keep things aligned
            const scrollLeft = this.$el.scrollLeft();
            this.$backdrop.css(
              "transform",
              scrollLeft > 0 ? "translateX(" + -scrollLeft + "px)" : ""
            );
          },

          // in Chrome, page up/down in the textarea will shift stuff within the
          // container (despite the CSS), this immediately reverts the shift
          blockContainerScroll: function () {
            this.$container.scrollLeft(0);
          },

          destroy: function () {
            this.$backdrop.remove();
            this.$el
              .unwrap()
              .removeClass(ID + "-text " + ID + "-input")
              .off(ID)
              .removeData(ID);
          },
        };

        // register the jQuery plugin
        $.fn.highlightWithinTextarea = function (options) {
          return this.each(function () {
            const $this = $(this);
            let plugin = $this.data(ID);

            if (typeof options === "string") {
              if (plugin) {
                switch (options) {
                  case "update":
                    plugin.handleInput();
                    break;
                  case "destroy":
                    plugin.destroy();
                    break;
                  // default:
                  //   // console.error("unrecognized method string");
                }
              } else {
                console.error("plugin must be instantiated first");
              }
            } else {
              if (plugin) {
                plugin.destroy();
              }
              plugin = new HighlightWithinTextarea($this, options);
              if (plugin.isGenerated) {
                $this.data(ID, plugin);
              }
            }
          });
        };
        // })()
      })(jQuery);
    },
  },
  beforeDestroy() {
    $(".message-box .el-textarea .el-textarea__inner").highlightWithinTextarea(
      "destory"
    );
  },
  components: {
    InsertBook,
    InsertPage,
    SelectTitle,
    SelectImage,
    SelectDescription,
    InsertActivity,
    InsertMiniProgram,
    UserAvatar,
    InsertTemplate,
    InsertBookCoin,
  },
}
</script>
<style lang='scss' scoped>
@import url("../../service/script/highlight.css");

.form-container {
  width: 1300px;
  margin: auto;
}

.form-container-left {
  width: 600px;
  margin-left: auto;
}

.form-container-right {
  width: 600px;
  margin-right: auto;
}

/deep/ .el-button {
  margin-right: 0px !important;
  margin-left: 0px;
}

/deep/ .el-drawer__body {
  overflow-y: auto;
}

/deep/ .el-drawer {
  padding: 20px;

  /deep/ .el-drawer__header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }
}

// /deep/ .el-input-number__decrease{
//   top:1px;
// }
a {
  -webkit-tap-highlight-color: transparent;
}

.example {
  width: 375px;
  background-color: #f1f1f1;
  padding: 10px;
}

.card {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
}

.mesg-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-weight: bold;
  line-height: 20px;
}

.detail {
  display: flex;
  align-items: center;
}

.detail p {
  flex: 1;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 15px;
}

.detail img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: #f5f7fa;
  color: #909399;
}

.message-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-mask {
  border: 1px solid #c0c4cc;
  border-radius: 4px;
  min-height: 259px;
}

.dialog-footer {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

/* .text-mask > span {
  padding-left: 10px;
  font-size: 12px;
} */

/**
**
**改版客服消息排版相关css
**
**/
// 新增属性
.inner-form {
  width: 480px;
  border: 1px solid #f6f6f6;
  border-radius: 20px;
  padding: 10px;
  background-color: #f6f6f6;
  margin-left: -10px;
}

.form-container /deep/ .el-form-item__label {
  font-size: 13px;
}

.form-container /deep/ .el-radio-button--medium .el-radio-button__inner {
  font-size: 13px;
}

.form-container .el-radio__label {
  font-size: 13px;
}

/deep/ .el-dialog__body {
  padding-bottom: 0 !important;
  padding-top: 10px !important;
}

.el-form-item--small.el-form-item {
  margin-bottom: 10px;
}

.form-top {
  background-color: #fbfbfb;
  padding: 10px 20px 0 20px;
}

/deep/ .necessary > .el-form-item__label {
  position: relative;
}

/deep/ .necessary > .el-form-item__label::before {
  position: absolute;
  top: 0;
  left: -8px;
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}

.form-body {
  background-color: #fbfbfb;
  margin-top: 10px;
  padding: 0 20px;
  padding-bottom: 0;
  display: flex;

  .message-box {
    justify-content: flex-start;
  }

  .form-body-left {
    border-right: 1px dashed #d9d9d9;
  }

  .form-body-left,
  .form-body-right {
    padding-top: 10px;
    width: 630px;
  }

  .form-body-right {
    padding-left: 85px;
    position: relative;

    .form-body-right-mask {
      z-index: 9999;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(#ababab, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: 16px;
        color: #fff;
      }
    }

    .vip-consume {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .el-radio-group {
        .el-radio {
          margin: 0 5px !important;
        }
      }

      .consume-box {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        i {
          margin: 0 10px 10px 0;
        }
      }
    }

    .rfm-box {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      i {
        margin: 0 10px 10px 0 !important;
      }
    }
  }
}

.dialog-footer {
  .test-box {
    width: 520px;
    margin: auto;

    /deep/ .el-form-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .sub-box {
    display: flex;
    width: 700px;
    margin: auto;
    justify-content: flex-start;
    align-items: center;

    .person-count {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

// 图片选择
.image-text-box {
  cursor: pointer;
  display: block;
  width: 500px;
  height: 400px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 5px 15px;
  background-color: #fff;
  color: #8b8b8b;
  line-height: 16px;
  position: relative;

  > p {
    display: inline-block;
    width: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    text-align: center;
    color: #ccc;
  }

  .material-item {
    width: 300px;

    p {
      padding: 5px;
    }

    img {
      max-width: 300px;
      max-height: 300px;
    }
  }
}

// 小程序图片选择
.selectMiniPicContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .changeMiniPicType {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 20px;

    label {
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
  }
}
</style>
