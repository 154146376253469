<template>
  <div class="batch-send">
    <div class="border-radius-6 box-shadow-light-grey padding-20">
      <dy-table
        :dataList="dataList"
        :rowList="rowList"
        :isUseCheckbox="true"
        :showPagination="true"
        :tagInfo="tagInfo"
        :page="page"
        :pageSize="pageSize"
        :total="total"
        height="72vh"
        @pageChange="pageChange"
        @selection-change="selectionChange"
      >
        <template #filter>
          <c-button
            @click="
              (cb) => {
                initData(cb);
              }
            "
          >刷新数据
          </c-button>
          <el-button @click="addItem" type="primary" class="margin-right-ten">新增
          </el-button>
          <c-button
            :autoConfirm="true"
            @confirm="(cb) => { batchCopyMessage(cb) }"
            message="是否进行批量复制"
            type="warning"
            style="margin-left:1px"
            class="margin-right-ten"
            :disabled="selection.length === 0"
          >批量复制</c-button>
          <el-select
            v-model="params.platform"
            clearable
            filterable
            placeholder="平台"
            class="margin-right-ten"
            style="width: 120px"
            @change="initData"
          >
            <el-option
              v-for="item in messagePlatformListDetail"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
          <el-select
            v-model="params.tickStatus"
            style="width: 100px"
            class="margin-right-ten"
            placeholder="状态"
            clearable
            filterable
            @change="initData"
          >
            <el-option label="未开始" :value="1"></el-option>
            <el-option label="发送中" :value="2"></el-option>
            <el-option label="已完成" :value="3"></el-option>
          </el-select>
          <el-select
            v-model="params.categoryId"
            placeholder="请选择分类"
            clearable
            @change="initData"
            class="margin-right-ten"
          >
            <el-option
              v-for="item in categoryList"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
          <c-input
            placeholder="备注"
            style="width: 200px;"
            class="margin-right-ten"
            v-model="params.remark"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="initData"
            ></el-button>
          </c-input>
        </template>
        <template #msgtype="{ row }">
          <el-tag v-if="row.msgtype === 'text'" type="info">文本</el-tag>
          <el-tag v-else-if="row.msgtype === 'news'">图文</el-tag>
          <el-tag v-else-if="row.msgtype === 'voice'" type="success">音频</el-tag>
          <el-tag v-else-if="row.msgtype === 'image'" type="success">图片</el-tag>
          <el-tag v-else-if="row.msgtype === 'video'" type="success">视频</el-tag>
          <el-tag v-else-if="row.msgtype === 'miniprogrampage'" type="success">小程序卡片</el-tag>
          <el-tag v-else type="danger">未知</el-tag>
        </template>
        <template #platform="{ row }">
          <span>{{ getPlatform(row.platform) }}</span>
        </template>
        <template #categoryId="{ row }">
          <span>{{ getTags(row.categoryId) }}</span>
        </template>
        <template #operation="{ row }">
          <c-button
            type="primary"
            size="small"
            class="button-small"
            @click="(cb) => {
                editItem(row, cb);
            } "
          >编辑
          </c-button>
          <c-button
            type="warning"
            size="small"
            class="button-small"
            :autoConfirm="true"
            message="确定复制此条信息？"
            @confirm="(cb) => {
                copyItem(row.id, cb);
            } "
          >复制
          </c-button>
          <c-button
            type="danger"
            size="small"
            class="button-small"
            :autoConfirm="true"
            @confirm="(cb) => {
                deleteData(row.id, cb);
            } "
          >删除
          </c-button>
        </template>
      </dy-table>
    </div>
    <add-message
      :show="showEditDialog"
      :isEdit="isEdit"
      :id="this.id"
      :classifyList="categoryList"
      @close="showEditDialog = false"
      @refresh="pageChange(1)"
    >
    </add-message>
  </div>
</template>
<script>
import {platformList, messagePlatformListDetail} from "@/assets/js/options.js";
import addMessage from "./add-message";
import {
  getCustomerBatchList,
  deleteCustomerBatch,
  copyCustomerBatch,
} from "../../../api/service";
import {getClassifyList} from "@/api/classify";

export default {
  data() {
    return {
      page: 1,
      pageSize: 15,
      total: 0,
      params: {},
      isEdit: false,
      showEditDialog: false,
      categoryList: [],
      id: null,
      selection: [],
      dataList: [],
      rowList: [
        {
          label: "id",
          prop: "id",
          width: 50
        },
        {
          label: "平台",
          prop: "platform",
          slot: true,
          width: 100
        },
        {
          label: "标签",
          prop: "categoryId",
          slot: true,
          width: 100
        },
        {
          label: "消息类型",
          prop: "msgtype",
          slot: true,
        },
        {
          label: "定时时间",
          prop: "tick",
          width: 100,
        },
        {
          label: "状态",
          prop: "tickStatus",
          tag: true,
        },
        {
          label: "修改日期",
          prop: "updatedTime",
          width: 100,
        },
        {
          label: "备注",
          prop: "remark",
          popover: true,
        },
        {
          label: "失败原因",
          prop: "failReason",
          popover: true,
          rowHtml: true,
          width: 150,
        },
        {
          label: "操作",
          action: "operation",
          width: 150,
        },
      ],
      tagInfo: [
        {
          tagName: "tickStatus",
          tagVal: [
            {
              val: 1,
              text: "未开始",
            },
            {
              val: 2,
              text: "发送中",
            },
            {
              val: 3,
              text: "已完成",
            },
          ],
        },
      ],
      messagePlatformListDetail
    };
  },
  mounted() {
    this.initData();
    getClassifyList({type: 1}).then(res => {
      this.categoryList = res
    })
  },
  methods: {
    initData(cb) {
      getCustomerBatchList({
        page: this.page,
        pageSize: this.pageSize,
        tickStatus: this.params.tickStatus,
        remark: this.params.remark,
        platform: this.params.platform,
        categoryId: this.params.categoryId
      }).then((res) => {
        this.dataList = res.list;
        this.total = res.total;
      }).finally(() => {
        if (typeof cb === "function") {
          cb();
        }
      });
    },
    pageChange(val) {
      this.page = val;
      this.initData();
    },
    getPlatform(platform) {
      if (!platform) {
        return;
      }
      let platformStr = "";
      let split = platform.split(",");
      let le = 0;
      split.map((id) => {
        le++;
        platformStr = platformStr + platformList[id];
        if (split.length !== le) {
          platformStr = platformStr + '、'
        }
      })
      return platformStr
    },
    getTags(categoryIds) {
      if (!categoryIds) {
        return;
      }
      if (this.categoryList.length === 0) {
        return;
      }
      let str = "";
      let split = categoryIds.split(",");
      let le = 0;
      split.map((id) => {
        le++;
        str = str + this.categoryList.find((item) => item.id === Number(id)).name;
        if (split.length !== le) {
          str = str + '、'
        }
      })
      return str;
    },
    addItem() {
      this.isEdit = false;
      this.id = null;
      this.showEditDialog = true;
    },
    editItem(row, cb) {
      this.isEdit = true;
      this.id = row.id;
      this.tickStatus = row.tickStatus;
      cb();
      this.showEditDialog = true;
    },
    copyItem(id, cb) {
      copyCustomerBatch(id).then(() => {
        this.$message.success("复制成功");
        this.initData(cb);
      }).finally(() =>{
        cb();
      })
    },
    batchCopyMessage(cb){
      const ids =  this.selection.map(item => item.id).join(',')
      copyCustomerBatch(ids).then(() => {
        this.$message.success("复制成功");
        this.initData(cb);
      }).finally(() =>{
        cb();
      })
    },
    deleteData(id, cb) {
      deleteCustomerBatch(id).then(() => {
        this.$message.success("删除成功");
        this.initData(cb);
      }).finally(() => {
        cb();
      })
    },
    selectionChange(select) {
      this.selection = select;
    },
  },
  components: {
    addMessage
  }
}
</script>
<style lang='scss' scoped>
.batch-send {
background: #fff;
}
</style>
